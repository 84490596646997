import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, description, image, image_resize }) => {

  return (
    <Helmet title={title}>

      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image"  content={image} />
      <meta property="og:image:secure_url"  content={image} />      
      <meta property="og:image"  content={image_resize} />
      <meta property="og:image:secure_url"  content={image_resize} />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default SEO